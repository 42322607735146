import React, {useEffect} from "react"


const IndexPage = () => {
  useEffect(() => {
    window.location.href = "https://hapunjack.com";
  })
  return (
    <>
      <div className="moved-container">
        <div>
          <h1>
            מיד תועברו לאתר החדש...
          </h1>
        </div>
      </div>
      <div className="moved-link">
        <a href="https://hapujack.com">
          לאתר החדש
        </a>
      </div>
    </>
  )
}
export default IndexPage
